import { SDK_EVENTS } from "../constants";

class SdkEvent extends Event {
    public readonly detail: Record<string, unknown>;

    constructor(type: SDK_EVENTS, detail: Record<string, unknown>) {
        super(type);
        this.detail = detail;
    }
}

export default SdkEvent;
