import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import { singleton } from "tsyringe";

import { SDK_EVENTS, SDK_READY } from "./constants";
import SdkEvent from "./events/SdkEvent";
import SdkEventsInterface from "./events/SdkEventsInterface";
import SdkCommon from "./SdkCommon";
import { SdkInterface } from "./SdkInterface";
import { SdkRegisteredModuleInterface } from "./SdkRegisteredModuleInterface";
import { SdkInitOptions } from "./types";

@singleton()
class Sdk implements SdkInterface {
    private readonly _emitter: EventTarget;

    private readonly _modules: Record<string, SdkRegisteredModuleInterface> =
        {};

    public readonly common: SdkCommon;

    constructor(
        private readonly _initApp: (options: SdkInitOptions) => void,
        private readonly _productIdProvider: ProductIdFromSdkProvider,
        private readonly _variantIdProvider: ProductVariantIdFromSdkProvider
    ) {
        this._emitter = new EventTarget();
        this.common = new SdkCommon();
    }

    public init(options: SdkInitOptions) {
        this._initApp(options);
    }

    public start() {
        if (window && !window.gw.sdk) {
            window.gw.sdk = this;
            document.dispatchEvent(new Event(SDK_READY));
        }
    }

    public on(type: SDK_EVENTS, callback: (event: Event) => void) {
        this._emitter.addEventListener(type, callback);
    }

    public off(type: SDK_EVENTS, callback: (event: Event) => void) {
        this._emitter.removeEventListener(type, callback);
    }

    public dispatch(event: SdkEventsInterface[keyof SdkEventsInterface]) {
        this._emitter.dispatchEvent(
            new SdkEvent(event.type, { detail: event })
        );
    }

    public registerModule(module: SdkRegisteredModuleInterface) {
        module.register(this);
        this._modules[module.constructor.name] = module;
    }

    public setProductId(productId: number) {
        this._productIdProvider.setProductId(productId);
    }

    public setVariantId(variantId: number) {
        this._variantIdProvider.setVariantId(variantId);
    }
}

export default Sdk;
