import { Subject } from "rxjs";
import { singleton } from "tsyringe";

import { SdkMethodPayloads, SdkMethods } from "../types";

type Payload = SdkMethodPayloads[keyof SdkMethodPayloads];

@singleton()
class SdkMethodsCollector {
    public methods$: Subject<Payload>;

    readonly #subscribedMethods: Set<SdkMethods>;

    constructor() {
        this.methods$ = new Subject();
        this.#subscribedMethods = new Set();
    }

    public pushMethod(payload: Payload): void {
        this.methods$.next(payload);
    }

    public addSubscriber(methodName: SdkMethods) {
        this.#subscribedMethods.add(methodName);
    }

    public deleteSubscriber(methodName: SdkMethods) {
        this.#subscribedMethods.delete(methodName);
    }

    public hasSubscribers(methodName: SdkMethods) {
        return this.#subscribedMethods.has(methodName);
    }
}

export default SdkMethodsCollector;
