import { SDK_EVENTS } from "./constants";

class SdkCommon {
    public readonly eventNames: Record<SDK_EVENTS, SDK_EVENTS> = {
        [SDK_EVENTS.WIDGET_CLOSED]: SDK_EVENTS.WIDGET_CLOSED,
        [SDK_EVENTS.WIDGET_INJECTED]: SDK_EVENTS.WIDGET_INJECTED,
        [SDK_EVENTS.WIDGET_OPENED]: SDK_EVENTS.WIDGET_OPENED,
    };
}

export default SdkCommon;
